<template>
  <v-container>
    <v-row v-if="loading">
      <v-col md="6" v-for="number in 2" :key="'loader-' + number">
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          height="150"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else no-gutters>
      <v-col md="12">
        <v-card flat>
          <v-toolbar dense flat>
            <v-spacer></v-spacer>
            <v-btn color="primary" small @click="agregarConsejo = true">Agregar consejo</v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
      <v-col md="12">
        <v-card flat>
          <v-card outlined>
            <v-list v-for="(consejo, index) in consejos" :key="`consejo-${index}`">
              <template >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6">
                      <a
                        href="javascript:void(0)"
                        class="link"
                        @click="verConsejo(consejo._id)"
                      >
                        {{ consejo.nombre }}
                      </a>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Integrantes:
                      {{ consejo.integrantes }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index < consejos.length - 1"
                  :key="`div-${index}`"
                  class="mx-3"
                ></v-divider>
              </template>
            </v-list>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <agregarConsejoComponent
      v-if="agregarConsejo"
      :mostrar="agregarConsejo"
      @cancelar="agregarConsejo = false"
      @consejoAgregado="consejoAgregado"
    />
   
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import agregarConsejoComponent from "./agregarConsejo.vue";
import { getConsejosService } from "./consejos.service";

export default {
  name: "consejos",
  components: { agregarConsejoComponent },
  computed: {
    ...mapGetters(["userAdmin"]),
  },
  data() {
    return {
      loading: false,
      consejos: [],
      agregarConsejo: false,
    };
  },
  mounted() {
    this.cargarConsejos();
  },
  methods: {
    async cargarConsejos() {
      try {
        this.loading = true;
        const serverResponse = await getConsejosService();
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$notificarError(serverResponse.mensaje);
        } else {
          this.consejos = serverResponse.consejos;
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    verConsejo(idConsejo) {
      this.$router.push(`/consejo/${idConsejo}`);
    },
    consejoAgregado({ consejo }) {
      this.agregarConsejo = false;
      this.consejos.unshift(consejo);
    },
  },
};
</script>